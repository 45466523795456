
import { defineComponent } from 'vue';
import SearchTable from '@/components/table/SearchTable.vue';
import { useInject } from '@/utils/inject';

export default defineComponent({
  name: 'Menu',
  components: { SearchTable },
  setup() {
    const { https, mapper } = useInject();

    const tableOpts = {
      index: true,
      search: {
        fetch: true,
        remote: {
          action: https?.sys.pageMenu
        }
      },
      cols: [
        {
          field: 'label',
          label: '菜单名'
        },
        {
          field: 'name',
          label: '菜单编码'
        },
        {
          field: 'icon',
          label: '图标'
        },
        {
          field: 'url',
          label: '路径'
        },
        {
          field: 'type',
          label: '类型',
          options: mapper?.sys.menuTypes
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.common.enableStr
        },
        {
          field: 'remark',
          label: '备注',
          width: 200
        },
        {
          field: 'createTime',
          label: '创建时间',
          width: 200
        }
      ]
    };
    return { tableOpts };
  }
});
